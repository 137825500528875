import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoints from "../../utils/breakpoints";
import styled from "styled-components";
import { H2 } from "../typography";
import FoundersOverlay from "../founders-overlay";

const Container = styled.div`
  display:block;
  width:100%;
`;

const FeaturedFounders = styled.div`
  display:block;
  width:100%;
  background-color: var(--brand-blue);
  padding: 5.0rem var(--grid-padding) 3rem;

  @media ${breakpoints.tabletSm} {
    padding: 5.0rem var(--grid-padding) 0;
  }
  @media ${breakpoints.desktop} {
    padding: 5.0rem var(--grid-padding) 4.1rem;
  }

  h2{
    color: #FFF;
    font-size: 4rem;
  }
`;

const FoundersWrap = styled.div`
  display:flex;
  flex-wrap:wrap;
  width:100%;
  padding: 4rem 0 0;
  margin: 0px 0;

  @media ${breakpoints.tabletSm} {
    padding: 5.5rem 0 0;
    margin: 0px -0.4rem;
  }
  @media ${breakpoints.tablet} {
    padding: 6.9rem 0 0;
    margin: 0px -0.8rem;
  }
  @media ${breakpoints.desktop} {
    padding: 5.5rem 0 0;
    margin: 0px -1.4rem;
  }

  article{
    width: 100%;
    flex-basis: 100%;
    padding: 0;

    @media ${breakpoints.tabletSm} {
      width: 50%;
      flex-basis: 50%;
      padding: 0px 0.4rem;
    }
    @media ${breakpoints.tablet} {
      padding: 0px 0.8rem;
    }
    @media ${breakpoints.desktop} {
      padding: 0px 1.4rem;
    }
  }
`;

const FoundersCardStyle = styled.div`
  position: relative;
  display:block;
  cursor:pointer;
`;

const Content = styled.div`
  width: 100%;
  h2 {
    margin: 0;
    padding-bottom: 0.5rem;
    font-size: 3.2rem;
    line-height: 45px;
    color: #FFF;
    font-weight: 100;
    font-family: var(--font-secondary);
    text-align: left;
    @media ${breakpoints.tabletSm} {
      font-size: 3.2rem;
      padding-bottom: 0.5rem;
    }
    @media ${breakpoints.tablet} {
      font-size: 3.8rem;
      padding-bottom: 1rem;
    }
  }
`;

const ImageWrap = styled.div`
  position:relative;
  overflow:hidden;
  width: 100%;
  margin-bottom: 1rem;

  &:after{
    content:'';
    display:block;
    padding-bottom: 65%;
    @media ${breakpoints.desktop} {
      
    }
  }
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    @media ${breakpoints.desktop} {
      
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media ${breakpoints.tabletSm} {
    margin-bottom: 0.5rem;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 1rem;
  }
`;

const Company = styled.div`
  font-family: var(--font-primary);
  font-size: 1.6rem;
  font-weight: 500;
  color: #FFF;
  text-align: left;
  padding-bottom:5rem;
  @media ${breakpoints.tabletSm} {
    font-size: 1.6rem;
    padding-bottom:8rem;
  }
  @media ${breakpoints.tablet} {
    font-size: 1.9rem;
    padding-bottom:10rem;
  }
  @media ${breakpoints.desktop} {
    font-size: 1.9rem;
    padding-bottom:8.2rem;
  }
`;

const FoundersFeatured = ({ headline, entries, slug }) => {
  const [foundersDetail, setFoundersDetail] = useState(null);

  const openModal = e => {
    setFoundersDetail(e);
    window.history.pushState({}, '', '/founders/'+e.slug);
  };

  //open modal if slug present
  const entryToOpen = slug ? entries.find(e => e.slug === slug) : undefined;
  useEffect(() => {
    if (slug && entryToOpen) {
      openModal(entryToOpen);
    }
  }, []);

  return (
    <Container>
      {foundersDetail && (
        <FoundersOverlay
          allEntries={entries}
          onClose={() => {
            setFoundersDetail(null);
            window.history.pushState({}, '', '/founders');
          }}
          data={foundersDetail}
        />
      )}

      <FeaturedFounders>
      <H2>{headline}</H2>
      <FoundersWrap>
        {entries.map((e, index) => {
          return (
            <article key={e.id} onClick={() => openModal(e)}>
              <FoundersCardStyle>
                <ImageWrap>
                  {e.featured_image && (
                    <GatsbyImage
                      image={getImage(e.featured_image.imageFile)}
                      alt={e.featured_image.alt || e.title}
                    />
                  )}
                </ImageWrap>
                <Content>
                  <h2>{e.founder_name}</h2>
                  <Company dangerouslySetInnerHTML={{ __html: e.company }} />
                </Content>
              </FoundersCardStyle>
            </article>
          );
        })}
      </FoundersWrap>
    </FeaturedFounders>
  </Container>
  );
};

export default FoundersFeatured;
