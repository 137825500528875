import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Founders from "../components/founders";
import FoundersFeatured from "../components/founders-featured";

export default function Home({ path, data: { cms: { entry, founders, seo } } }) {
  const [ themeLogo, setthemeLogo ] = useState('');
  function changeThemeLogo(theme) {
    setthemeLogo(theme);
  }
  const entries = founders.data || [];

  const path_slug = path.split("/").pop();

  return (
    <Layout theme="dark" themeLogo={themeLogo}>
      <SEO
        title={seo.title}
        description={seo.og_description}
        meta={[{
          "property": "og:image",
          "content": "/images/og-updated.png"
        }]}
      />

      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "SoftBank Opportunity Fund",
          "url": "https://theopportunityfund.com",
          "logo": "https://theopportunityfund.com/images/og-updated.png",
          "alternateName": "The SoftBank Opportunity Fund",
          "sameAs": [
            "https://www.linkedin.com/company/sb-opportunity-fund"
          ]
        }
        `}
        </script>
      </Helmet>

      <Hero heading={entry.headline} body={entry.hero_body} show_border={false} stack_on_tablet={true}/>
      <FoundersFeatured headline={entry.founders_headline} entries={entries} slug={path_slug}/>

    </Layout>
  );
}


export const query = graphql`
query founders {
  cms {
    entry(id: "10e07239-2326-4916-8f9a-bc03003bf685") {
      ... on cms_Entry_Pages_PageFounders {
        id
        title
        headline
        founders_headline
        companies_headline
        hero_body
      }
    }
    founders: entries(collection: "founders", sort: "order") {
      data {
        id
        slug
        ... on cms_Entry_Founders_Founders {
          id
          company
          copy
          founder_date
          featured_image {
            path
            ... on cms_Asset_Assets {
              id
              alt
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1600
                    height: 880
                    formats: [AUTO, WEBP]
                  )
                }
                absolutePath
              }
            }
          }
          founder_name
          slug
          title
        }
      }
    }
    seo: SeoMeta(page_id: "10e07239-2326-4916-8f9a-bc03003bf685") {
      og_description
      og_title
      title
    }
  }
}
`