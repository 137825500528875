import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoints from "../../utils/breakpoints";
import styled from "styled-components";
import { H2 } from "../typography";
import CloseIcon from "../../assets/vectors/close.svg";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .inner {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background-color: var(--brand-off-white);
    height: 100%;
    max-height: 100vh;

    .scrollContainer {
      background-color: var(--brand-off-white);
      padding: 14.4rem var(--grid-padding) 8rem var(--grid-padding);
      width: 100%;
      max-height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      @media ${breakpoints.tabletSm} {
        padding: 13.4rem 0 8rem 0;
      }
      @media ${breakpoints.tablet} {
        padding: 14.4rem 0 8rem 0;
      }
    }

    @media ${breakpoints.tabletSm} {
      margin-top: 150px;
      padding-top: 0;
      max-height: calc(100vh - 150px);
      max-width: min(96.2rem, 80.3%);
    }

    @media ${breakpoints.tablet} {
      max-width: min(96.2rem, 85%);
    }
    @media ${breakpoints.desktop} {
      max-width: min(96.2rem, 66.8%);
    }

    &__content {
      padding: 6rem 0;
      margin: 0 auto;
      max-width: 100%;

      @media ${breakpoints.tabletSm} {
        max-width: calc(66.2% + var(--scrollbar-width));
      }
      @media ${breakpoints.tablet} {
        max-width: calc(66.2% + var(--scrollbar-width));
      }
      @media ${breakpoints.desktop} {
        max-width: calc(65.7% + var(--scrollbar-width));
        padding: 4.9rem 0 6rem;
      }

      a {
        color: #000;
      }
      h2 {
        text-align: center;
        color: var(--brand-blue);
        font-size: 3.2rem;
        padding-bottom: 4rem;
        @media ${breakpoints.tabletSm} {
          font-size: 4rem;
        }
      }
      .date{
        color: #000;
        text-align:center;
        font-size: 1.6rem;
        padding-bottom: 2.2rem;
      }
      .copyText {
        color: #000;
        font-size: 1.6rem;
        a{
          text-decoration:underline;
        }
        @media ${breakpoints.tabletSm} {
          font-size: 1.6rem;
        }
        @media ${breakpoints.tablet} {
          font-size: 1.9rem;
        }
      }
    }
  }
`;

const MediaWrap = styled.div`
  position:relative;
  overflow:hidden;
  width: 100%;
  margin-bottom: 1rem;
  margin: 0 auto;
  max-width: 100%;

  @media ${breakpoints.tabletSm} {
    margin-bottom: 0.5rem;
    max-width: calc(66.2% + var(--scrollbar-width));
  }
  @media ${breakpoints.desktop} {
    margin-bottom: 0.5rem;
    max-width: calc(65.7% + var(--scrollbar-width));
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 1rem;
  }

  &:after{
    content:'';
    display:block;
    padding-bottom: 65%;
    @media ${breakpoints.desktop} {
      
    }
  }
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    @media ${breakpoints.desktop} {
      
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  @media ${breakpoints.tablet} {
    top: 3.0rem;
    right: 3.0rem;
  }
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: 300ms ease;
  z-index: 2;
  &:hover {
    transform: scale(1.05);
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 3.3rem;
    height: 3.3rem;
    stroke: var(--brand-blue);
    line{
      stroke-width: 2.5;
    }
  }
`;

const resolveMonth = month => {
  switch (month) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
    default:
      return "";
  }
};

const FoundersOverlay = ({ data, onClose }) => {
  const [entryData, setEntryData] = useState(null);

  useEffect(() => {
    setEntryData(data);
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.height = "auto";
    };
  }, []);

  return (
    <Overlay key={entryData?.id}>
      <div className="inner">
        <Close onClick={() => onClose()}>
          <CloseIcon />
        </Close>
        <div className="scrollContainer">
          <MediaWrap>
            {entryData?.featured_image && (
              <GatsbyImage
                image={getImage(entryData?.featured_image.imageFile)}
                alt={entryData?.featured_image.alt || entryData?.title}
              />
            )}
          </MediaWrap>
          <div className="inner__content">
            {entryData?.founder_date && (
              <div className="date">
                {resolveMonth(new Date(entryData.founder_date).getMonth())}{" "}
                {new Date(entryData.founder_date).getDate()},{" "}
                {new Date(entryData.founder_date).getFullYear()}
              </div>
            )}
            <H2>{entryData?.title}</H2>
            <div
              className="copyText"
              dangerouslySetInnerHTML={{ __html: entryData?.copy }}
            />
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default FoundersOverlay;
