import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import breakpoints from "../../utils/breakpoints";
import { grid } from "../../utils";
import { H2 } from "../typography";

import arrow from "../../../static/images/arrow.png";

const Container = styled.div`
  position: relative;
  background-color: var(--brand-off-white);
  padding: 8rem var(--grid-padding) 14.1rem;
  overflow: hidden;
  color: #262626;

  @media ${breakpoints.tabletSm} {
    padding: 8rem var(--grid-padding) 14.1rem;
  }

  @media ${breakpoints.tablet} {
    padding: 8rem var(--grid-padding) 14.1rem;
  }

  @media ${breakpoints.desktop} {
    padding: 12rem var(--grid-padding) 24.1rem;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-pagination {
    bottom: -1.5rem;
  }

  .swiper-pagination-bullet {
    background: var(--brand-blue-gray);
    opacity: 1;
    transition: background 0.2s ease-out;
  }

  .swiper-pagination-bullet-active {
    background: var(--brand-blue);
  }
`;

const ScrollTarget = styled.span`
  display: block;
  position: absolute;
  top: -7.5rem;
  left: 0;
`;

const Wrapper = styled.div`
  @media ${breakpoints.tablet} {
    display: flex;
  }
`;

const Heading = styled.div`
  margin-bottom: 2.9rem;
  color: #3a797e;

  @media ${breakpoints.tabletSm} {
    margin-bottom: 2.9rem;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 0;
    flex: none;
    width: ${grid(6, true)};
    padding-right: ${grid(1, true)};
  }

  @media ${breakpoints.desktop} {
    width: ${grid(6, true)};
    padding-right: ${grid(0.5, true)};
  }

  p {
    font-size: 4rem;
    color: #224466;

    @media ${breakpoints.tabletSm} {
      max-width: 12em;
    }
    @media ${breakpoints.tablet} {
      max-width: 6em;
    }
    @media ${breakpoints.desktop} {
      max-width: 12em;
    }
  }
`;

const FoundersWrapper = styled.div`
  @media ${breakpoints.tabletSm} {
    display: flex;
  }
  @media ${breakpoints.tablet} {
    width: ${grid(6, true)};
    /* padding-left: calc(var(--grid-gutter) / 2); */
    position: relative;
    top: 0.2rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 1.5em;

    &:last-child {
      @media ${breakpoints.tablet} {
        margin-bottom: 0;
      }
    }
  }

  li .name,
  li span {
    display: block;
  }

  li .name {
    font-weight: 400;
  }

  li strong {
    font-weight: 400;
  }

  li a {
    display: inline-flex;
    align-items: center;
    color: #3a797e;

    &:after {
      content: "";
      background-image: url(${arrow});
      width: 0.8rem;
      height: 0.8rem;
      background-size: cover;
      margin-left: 0.5rem;
    }
  }
`;

const FoundersList = styled.div`
  @media ${breakpoints.tabletSm} {
    width: ${grid(6, true)};
    padding-right: ${grid(1)};
  }
  @media ${breakpoints.tablet} {
    width: ${grid(3, true)};
    padding-right: ${grid(1)};
  }
`;

export default function Founders({ heading, portfolioData }) {
  const half = Math.ceil(portfolioData.length / 2);
  const listLeft = portfolioData.slice(0, half);
  const listRight = portfolioData.slice(half);
  return (
    <Container>
      <ScrollTarget id="founders"></ScrollTarget>
      <Wrapper>
        <Heading>
          <Fade bottom distance="1.5rem" duration={800}>
            <H2 as="p">{heading}</H2>
          </Fade>
        </Heading>
        <FoundersWrapper>
          <FoundersList>
            <ul>
              {listLeft.map((founder, i) => {
                // if (!founder.enabled) {
                //   return false;
                // }

                return (
                  <li key={i}>
                    <div className="name">{founder.founders}</div>
                    <span>
                      {founder.founder_label && `${founder.founder_label}, `}{" "}
                      <strong>{founder.title}</strong>
                    </span>
                  </li>
                );
              })}
            </ul>
          </FoundersList>
          <FoundersList>
            <ul>
              {listRight.map((founder, i) => {
                // if (!founder.enabled) {
                //   return false;
                // }

                return (
                  <li key={i}>
                    <div className="name">{founder.founders}</div>
                    <span>
                      {founder.founder_label && `${founder.founder_label}, `}{" "}
                        <strong>{founder.title}</strong>
                    </span>
                  </li>
                );
              })}
            </ul>
          </FoundersList>
        </FoundersWrapper>
      </Wrapper>
    </Container>
  );
}
